// import { parseCookies } from "../helpers/";

export default function Custom404() {
  const title = "Erro 404 - Página não encontrada";

  return <div className="app-wrapper">{title}</div>;
}

// Custom404.getInitialProps = ({ req }) => {
//   const cookies = parseCookies(req);

//   return {
//     initialUserLogged: cookies.userLogged || false,
//   };
// };
